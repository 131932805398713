import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import BackgroundImage from 'gatsby-background-image';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import UniversalLink from '../../components/UniversalLink';
import Icon from 'react-icons-kit';
import { longArrowRight } from 'react-icons-kit/fa/longArrowRight';
import { linkedin } from 'react-icons-kit/fa/linkedin';
import { github } from 'react-icons-kit/fa/github';
import { codepen } from 'react-icons-kit/fa/codepen';

const iconSvgs = {
  longArrowRight: longArrowRight,
  linkedin: linkedin,
  github: github,
  codepen: codepen
};

const Footer = ({
  row,
  col,
  titleLarge,
  titleSmall,
  buttonOutline,
  buttonSocial,
  iconSocial,
}) => {
  const available = false;
  const availableText = (available)
    ? "I am currently available for work and open to new opportunities. My inbox is always open! Whether you want to hire me, have a question or just want to say hello, send me a message and I will get back to you within one business day."
    : "Although I am currently not available to new opportunities, my inbox is always open! Whether you have a question or just want to say hello, send me a message and I will get back to you as soon as possible.";

  const data = useStaticQuery(graphql`
    fragment MenuItem on WPGraphQL_MenuItem {
      id
      databaseId
      label
      url
      path
      locations
    }
    fragment Widget_SocialButton on WPGraphQL_Widget_Widgetbuilder_Layouts_SocialButton {
      fieldGroupName
      socialAccount
      accountLink
    }
    fragment Widget_Text on WPGraphQL_Widget_Widgetbuilder_Layouts_Text {
      fieldGroupName
      text
    }

    query GET_FOOTER_ELEMENTS {
      background: file(
        sourceInstanceName: { eq: "assets" },
        relativePath: { eq: "images/background-texture.png" }
      ) {
        childImageSharp {
          fluid(
            traceSVG: { color: "#fff" }
            maxWidth: 1000
            quality: 100
          ) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      wpgraphql {
        menuItems(where: {location: PRIMARY}) {
          nodes {
            ...MenuItem
          }
        }
        connectWithMe: widget(id: "/widgets/connect-with-me/", idType: URI) {
          databaseId
          uri
          title
          widgetBuilder {
            layouts {
              ...Widget_SocialButton
            }
          }
        }
        sayHello: widget(id: "/widgets/say-hello/", idType: URI) {
          databaseId
          uri
          title
          widgetBuilder {
            layouts {
              ...Widget_Text
            }
          }
        }
      }
    }
  `);

  const background = data?.background.childImageSharp?.fluid;
  const menu = data?.wpgraphql.menuItems?.nodes;
  const year = new Date().getFullYear();

  const connectWithMe = {
    title: data?.wpgraphql.connectWithMe?.title,
    icons: data?.wpgraphql.connectWithMe?.widgetBuilder?.layouts
  };
  const sayHello = {
    title: data?.wpgraphql.sayHello?.title,
    email: data?.wpgraphql.sayHello?.widgetBuilder?.layouts[0]
  };

  return (
    <FooterArea {...row}>
      <CtaArea
        tag="div"
        fluid={background}
        {...col}
      >
        <Box className="cta-wrapper">
          <Heading {...titleLarge} content="Let's Work Together" as="h3" />
          <Text content={availableText} />
          <UniversalLink to="/contact/">
            <Button
              title="Contact Me"
              variant="outlined"
              className="btn"
              icon={<Icon icon={iconSvgs['longArrowRight']} size={17} />}
              {...buttonOutline}
            />
          </UniversalLink>
        </Box>
      </CtaArea>
      <MenuArea {...col}>
        <WidgetArea>
          <Box {...col} className="widget menu-widget">
            <Heading {...titleSmall} content="Quick Links" as="h3" />
            {!!menu && (
              <MenuList>
                {menu.map((item, index) => (
                  <MenuItem key={`footer-menu-link-${item.databaseId}`}>
                    <UniversalLink to={item.path}>
                      {item.label}
                    </UniversalLink>
                  </MenuItem>
                ))}
              </MenuList>
            )}
          </Box>
          <Box {...col} className="widget-group">
            {!!connectWithMe && (
              <Box className="widget social-widget">
                <Heading {...titleSmall} content={connectWithMe.title} as="h3" />
                <Box className="social-group">
                  {connectWithMe.icons.map((icon, index) => (
                    <UniversalLink
                      to={icon.accountLink}
                      key={`footer-icon-${index}`}
                    >
                      <Button
                        className="btn-social"
                        aria-label={`footer-${icon.socialAccount}-icon`}
                        icon={<Icon {...iconSocial} icon={iconSvgs[icon.socialAccount]} />}
                        {...buttonSocial}
                      />
                    </UniversalLink>
                  ))}
                </Box>
              </Box>
            )}
            {!!sayHello && (
              <Box className="widget email-widget">
                <Heading {...titleSmall} content={sayHello.title} as="h3" />
                <a href={`mailto:${sayHello.email.text}`} rel="noopener noreferrer">
                  <Button
                    title={sayHello.email.text}
                    variant="textButton"
                  />
                </a>
              </Box>
            )}
          </Box>
        </WidgetArea>
        <SocketArea {...row}>
          <Text content={`© Copyright ${year}, Ruben Klein`} />
        </SocketArea>
      </MenuArea>
    </FooterArea>
  );
};

Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  titleLarge: PropTypes.object,
  titleSmall: PropTypes.object,
  buttonOutline: PropTypes.object,
  buttonSocial: PropTypes.object,
  iconSocial: PropTypes.object,
};

Footer.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    width: '100%',
  },
  col: {
    width: ['100%', '100%', '100%', '50%', '50%'],
  },
  titleLarge: {
    fontSize: ['2rem', '2rem', '2rem', '2rem', '2.7rem'],
    fontWeight: '700',
    letterSpacing: '-0.02em',
    margin: '3rem 0 1.5rem 0',
    lineHeight: '1.2',
    textAlign: 'left',
  },
  titleSmall: {
    fontSize: '1.125rem',
  },
  buttonOutline: {
    type: 'button',
    colors: 'primary',
  },
  buttonSocial: {
    type: 'button',
    colors: 'primaryWithBg',
  },
  iconSocial: {
    size: '22',
  }
};

const FooterArea = styled.footer`
  padding-top: 7rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  color: ${themeGet('colors.white', '#ffffff')};
  .col {
    width: calc(100% / 2);
    padding: 0;
  }
`;

const CtaArea = styled(BackgroundImage)`
  background-color: ${themeGet('colors.primary', '#09142e')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% / 2);
  .cta-wrapper {
    padding: 3rem 5rem;
    @media only screen and (max-width: 767px) {
      padding: 3rem;
    }
  }
  h3 {
    margin-bottom: 1.5rem;
    @media only screen and (max-width: 989px) {
      margin-bottom: 1rem;
    }
  }
  p {
    margin-bottom: 3em;
    @media only screen and (max-width: 989px) {
      margin-bottom: 3rem;
    }
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

const MenuArea = styled.div`
  width: calc(100% / 2);
  background-color: ${themeGet('colors.footerBg', '#27293e')};
  color: ${themeGet('colors.white', '#ffffff')};
  margin-top: 100px;
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-top: 0;
  }
`;

const WidgetArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  padding: 3rem 5rem 0 5rem;
  @media only screen and (max-width: 767px) {
    padding: 3rem 3rem 0 3rem;
  }
  .widget {
    margin-bottom: 3rem;
  }
  .email-widget button {
    .btn-text {
      color: ${themeGet('colors.white70', 'rgba(255, 255, 255, 0.7)')};
      transition: all 0.2s ease;
      &:hover {
        color: ${themeGet('colors.white', '#fff')};
      }
    }
  }
  .col {
    width: calc(100% / 2);
    h3 {
      margin-bottom: 3rem;
      @media only screen and (max-width: 989px) {
        margin-bottom: 1rem;
      }
    }
    .row {
      width: 100%,
    }
  }
`;

const MenuList = styled.ul``;

const MenuItem = styled.li`
  padding: .6rem 0;
  a {
    color: ${themeGet('colors.white70', 'rgba(255, 255, 255, 0.7)')};
    transition: all 0.2s ease;
    &:hover,
    &:focus {
      color: ${themeGet('colors.white', '#ffffff')};
      outline: 0;
      text-decoration: none;
    }
  }
`;

const SocketArea = styled.div`
  background-color: ${themeGet('colors.socketBg', '#171825')};
  color: ${themeGet('colors.white50', 'rgba(255, 255, 255, 0.5)')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
  width: 100%;
  padding: 0 5rem;
  @media only screen and (max-width: 767px) {
    padding: 0 3rem;
    width: 100%;
    margin-top: 0;
  }
  p {
    margin-bottom: 0;
  }
`;

export default Footer;
